import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { removeSpaces } from '../../../shared/lib/utils';
import api from '../shared/lib/api';
import { IVeileder, Dokument, IFlags, DokumentType } from '../shared/lib/types';
import Spinner from '../spinner/Spinner';
import FeilmeldingSide from '../feilside/FeilmeldingSide';
import toast from 'react-hot-toast';
import VeilederDigitalSignering from './VeilederDigitalSignering';
import { DownloadSimple, MicrosoftWordLogo } from '@phosphor-icons/react';
import { FilePdf } from '@phosphor-icons/react/dist/ssr';

type TParams = {
    veilederId?: string;
    kundeId?: string;
};

const VeilederOppsummering = () => {
    let navigate = useNavigate();
    const { veilederId, kundeId } = useParams<TParams>();
    const [veileder, setVeileder] = useState<IVeileder>();
    const [includeFlags, setIncludeFlags] = useState<Array<IFlags>>();
    const [kundeNavn, setKundeNavn] = useState<string>();
    const [dokumenter, setDokumenter] = useState<Array<Dokument>>();
    const [egetforetak, setEgetforetak] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [serverErrorCode, setServerErrorCode] = useState<number>(0);
    const [ksDebugger, setKsDebugger] = useState<boolean>(false);
    const [digitalSignering, setDigitalSignering] = useState<boolean>(false);
    const isExternalApp = window.isExternalApp;

    const onFerdigstillClick = () => {
        api.ferdigstillPost(kundeId, veilederId)
            .then(() => {
                if (isExternalApp) return window.close();
                return navigate(`/kunder/${removeSpaces(kundeId)}/genererte-dokumenter`);
            })
            .catch(() => toast.error('Veilederen kunne ikke ferdigstilles.'));
    };

    const onSendToDigitalSignering = (dokumentId: string, tittel: string) => {
        api.ferdigstillPost(kundeId, veilederId)
            .then(() => {
                navigate(`/kunder/send-epost-signering/${removeSpaces(kundeId)}/${removeSpaces(dokumentId)}/${tittel}`);
            })
            .catch(() => toast.error('Veilederen kunne ikke ferdigstilles.'));
    };

    const onCloseClick = () => {
        if (isExternalApp) return window.close();
        return navigate(`/kunder/${removeSpaces(kundeId)}/kundeside`);
    };

    useEffect(() => {
        (() => {
            Promise.all([
                api.getInnloggetBruker().then((res) => {
                    if (res.regnskapsforetakId === kundeId) {
                        setKundeNavn(res.regnskapforetakNavn);
                        setEgetforetak(true);
                    } else {
                        api.getKunde(kundeId).then((kundeRes) => setKundeNavn(kundeRes.navn));
                    }
                    setKsDebugger(res.ksDebugger ?? false);
                }),
                api.getVeileder(veilederId, kundeId).then((veileder) => setVeileder(veileder)),
                api.getDokumentForVeileder(kundeId, veilederId).then((res) => setDokumenter(res)),
                api.getActiveVeileder(veilederId, kundeId).then((res) => setIncludeFlags(res.veileder.includeFlags)),
                isExternalApp
                    ? api.getDigitalSigneringEksterntForetak().then((res) => setDigitalSignering(res))
                    : api.getDigitalSigneringForetak().then((res) => setDigitalSignering(res)),
            ])
                .catch((error) => {
                    setServerErrorCode(error.status);
                })
                .finally(() => setLoading(false));

            document.title = 'KS Komplett - Veileder oppsummering';
        })();
    }, [veilederId, kundeId]);

    if (loading) {
        return <Spinner loading={loading} text="Laster..." />;
    }

    if (serverErrorCode) {
        return <FeilmeldingSide errorCode={serverErrorCode} url="/veiledere" />;
    }

    const pdfDokument = dokumenter?.filter((d) => d.dokumenttype === DokumentType.pdf && d.parentPdfId === null);

    return (
        <>
            {veileder && (
                <div className="veileder">
                    <div className="page-header page-header--dark page-header--sm">
                        <div className="container">
                            <h1>{veileder.name}</h1>
                        </div>
                    </div>
                    <div className="page-header">
                        <div className="container">
                            <div className="col-md-6 d-flex align-items-center">
                                <span className={`icon ${egetforetak ? 'icon-eget-regnskapsforetak' : 'icon-kundenavn-stor'}`} />
                                {kundeNavn && <h2>{kundeNavn}</h2>}
                            </div>
                        </div>
                    </div>
                    <div className="page">
                        <div className="container veileder-oppsummering">
                            <div className="row justify-content-around">
                                <div className="col-md-8 col-xl-8">
                                    <div className="section section--white">
                                        <h2>Oppsummering veileder</h2>
                                        <h3>{veileder.name}</h3>
                                        <div className="upper">Genererte dokumenter</div>
                                        <div className="row veileder-oppsummering-dokumenter__word">
                                            {dokumenter
                                                ?.filter((d) => d.parentPdfId !== null)
                                                .reverse()
                                                .map(({ tittel, dokumenttype, id }: Dokument) => (
                                                    <>
                                                        <div className="col-8 d-md-flex align-items-center veileder-oppsummering-label">
                                                            <span className="veilder-oppsummering__text d-flex align-items-end gap-5">
                                                                {dokumenttype === DokumentType.word ? (
                                                                    <MicrosoftWordLogo size={38} />
                                                                ) : (
                                                                    <FilePdf size={38} />
                                                                )}
                                                                <a
                                                                    href={`/fil/lastneddokument/${kundeId}/${id}`}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                    download={true}
                                                                >
                                                                    {tittel}
                                                                </a>
                                                            </span>
                                                        </div>
                                                        <div className="col-4 veileder-oppsummering__actions">
                                                            <a
                                                                href={`/fil/lastneddokument/${kundeId}/${id}`}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                className="btn btn--primary btn--sm icon-download"
                                                                download={true}
                                                            >
                                                                <DownloadSimple id="icon" size={28} />
                                                            </a>
                                                        </div>
                                                    </>
                                                ))}
                                        </div>
                                        {dokumenter?.some((d) => d.dokumenttype === DokumentType.pdf && d.parentPdfId === null) ? (
                                            <>
                                                <hr />
                                                <div className="row veileder-oppsummering-dokumenter__pdf">
                                                    <span className="pdf-infotext">Ved signering sendes alle bilag i et samledokument</span>
                                                    {pdfDokument.map(({ tittel, id }: Dokument) => (
                                                        <>
                                                            <div className="col-8 d-md-flex align-items-center veileder-oppsummering-label">
                                                                <span className="veilder-oppsummering__text d-flex align-items-end gap-5">
                                                                    <FilePdf size={38} />
                                                                    <a
                                                                        href={`/fil/lastneddokument/${kundeId}/${id}`}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                        download={true}
                                                                    >
                                                                        {tittel}
                                                                    </a>
                                                                </span>
                                                            </div>
                                                            <div className="col-4 veileder-oppsummering__actions">
                                                                <a
                                                                    href={`/fil/lastneddokument/${kundeId}/${id}`}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                    className="btn btn--primary btn--sm icon-download"
                                                                    download={true}
                                                                >
                                                                    <DownloadSimple id="icon" size={28} />
                                                                </a>
                                                            </div>
                                                        </>
                                                    ))}
                                                </div>
                                                <hr />
                                            </>
                                        ) : null}

                                        <div className="veileder-oppsummering__konklusjon">Oppsummering og konklusjon</div>
                                        <div dangerouslySetInnerHTML={{ __html: veileder.summaryText }}></div>
                                        {ksDebugger && (
                                            <ul>{includeFlags && includeFlags?.filter((i) => i.include).map(({ key }) => <li>[{key}]</li>)}</ul>
                                        )}
                                        <div className="row">
                                            <div className="col-md-6 d-md-flex align-items-center">
                                                <Link to={`/kunder/${kundeId}/veileder/${veilederId}/steg`} className="btn btn--underline p-0">
                                                    Tilbake til veileder
                                                </Link>
                                            </div>
                                            <div className="col-md-6 veileder-oppsummering__actions">
                                                <button className="btn btn--secondary" onClick={onCloseClick}>
                                                    Lukk kladd
                                                </button>
                                                <Link to="#" className="btn btn--primary" onClick={onFerdigstillClick}>
                                                    Lagre som endelig
                                                </Link>
                                                {(isExternalApp && digitalSignering) ||
                                                (veileder.veilederKanSigneres && digitalSignering && pdfDokument.length > 0) ? (
                                                    <Link
                                                        to="#"
                                                        onClick={() => onSendToDigitalSignering(pdfDokument[0].id, pdfDokument[0].tittel)}
                                                        className="btn btn--primary"
                                                    >
                                                        Send til signering
                                                    </Link>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {veileder.veilederKanSigneres ? (
                                    <VeilederDigitalSignering
                                        tittel={
                                            veileder.veilederKanSigneres && digitalSignering
                                                ? veileder.digitalSigneringTittel
                                                : veileder.bestillDigitalSigneringTittel
                                        }
                                        beskrivelse={
                                            veileder.veilederKanSigneres && digitalSignering
                                                ? veileder.digitalSigneringBeskrivelse
                                                : veileder.bestillDigitalSigneringBeskrivelse
                                        }
                                        lenke={veileder.veilederKanSigneres && digitalSignering ? null : veileder.bestillDigitalSigneringLenke}
                                        lenkeTekst={
                                            veileder.veilederKanSigneres && digitalSignering ? null : veileder.bestillDigitalSigneringLenkeTekst
                                        }
                                    />
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default VeilederOppsummering;
